#header-home {
  background: url("../images/lake-crescent.jpg") no-repeat center right/cover;
  height: 100vh;
  color: #fff;
}
#header-home .header-content {
  text-align: center;
  padding-top: 20%;
}
#header-home .header-content .typewriter {
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: 4px;
}
.lead {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}
@media (max-width: 800px) {
  #header-home {
    height: 30rem;
  }
  #header-home .header-content {
    padding-top: 5rem;
    display: none;
  }
}
@media (max-width: 500px) {
  #header-home {
    height: 10rem;
    border-bottom: 3px solid #ffbc00;
    background-position: 20% 30%;
  }
}
@media (max-height: 580px) {
  #header-home .header-content {
    padding-top: 3.5rem;
  }
  #header-home .header-content h1 {
    font-size: 2rem;
  }
}
