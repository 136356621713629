i {
  padding-left: 0.5rem;
}
#navbar {
  text-align: center;
  font-family: "Roboto Slab", serif;
}
#navbar ul {
  list-style: none;
  padding-left: 0;
}
#navbar ul li {
  color: #333;
  display: inline-block;
  padding: 1rem;
  position: relative;
  margin: 0 5rem;
}

#navbar ul li a {
  color: #333;
  text-decoration: none;
}

/* Hide nested ul by default */
#navbar ul li ul {
  display: none;
}

#navbar ul li:hover {
  cursor: pointer;
  background: #444;
  color: #fff;
}
/* Specifically selected link, as the color wasn't white on hover */
#navbar ul li:hover a {
  color: #fff;
}

/* Nested dropdown show */
#navbar ul li:hover ul {
  display: block;
  position: absolute;
  left: -80px;
  width: 325px;
  margin-top: 1rem;
}
/* target each list item */
#navbar ul li:hover ul li {
  display: block;
  background: #e7e7e7;
}
/* correct color from white */
#navbar ul li:hover ul li a {
  color: #333;
}
/* change hover state of nested li */
#navbar ul li:hover ul li:hover {
  background: #e0e0e0;
  color: inherit;
}

@media (max-width: 1275px) {
  #navbar ul li {
    margin: 0 3rem;
  }
  #navbar ul li:hover ul {
    left: -50px;
    width: 300px;
  }
}
