/* Site */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.5;
}
h1 {
  font-family: "Roboto Slab", serif;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
img {
  width: 100%;
}

/* Site Utility */
.py-2 {
  padding: 2rem 0;
}
.p-2 {
  padding: 2rem;
}
.container {
  text-align: center;
  max-width: 1100px;
  margin: auto;
  padding: 0 2rem;
  overflow: hidden;
}

/* Site Layout and Backgrounds */
.pimg1 {
  background-image: url("./images/javascript.jpg");
}
.pimg2 {
  background-image: url("./images/travel.jpg");
}
.pimg3 {
  background-image: url("./images/cat.jpg");
}
.pimg1,
.pimg2,
.pimg3 {
  min-height: 400px;
  position: relative;
  opacity: 0.7;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.ptext {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 27px;
  letter-spacing: 8px;
  text-transform: uppercase;
}
.border {
  background-color: #111;
  color: #fff;
  padding: 20px;
}
@media (max-width: 568px) {
  .pimg1,
  .pimg2,
  .pimg3 {
    background-attachment: scroll;
  }
}

/* Current Projects */
#cur-projs .project-container,
#complete-projs .project-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
#cur-projs .project-container,
#complete-projs .project-container {
  padding: 3rem 0;
}
@media (max-width: 768px) {
  #cur-projs .project-container,
  #complete-projs .project-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  #cur-projs .project-container,
  #complete-projs .project-container {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 450px) {
  .ptext {
    letter-spacing: normal;
  }
  .footer-container {
    text-align: center;
  }
}
@media (max-width: 300px) {
  .border {
    font-size: initial;
  }
}
