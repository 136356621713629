#about-me {
  height: 75vh;
  text-align: center;
}
#about-me h1 {
  font-size: 2.5rem;
  padding: 20px 0;
  margin: 2rem 0;
}
.about-me p {
  padding: 20px 30%;
}
#about-me .about-skills {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
#about-me .about-skills .skills h2 {
  justify-self: center;
}
#about-me .about-skills .about,
#about-me .about-skills .skills {
  flex-basis: 50%;
  width: 100%;
}
#about-me .about-skills .about p {
  padding: 20px;
}
#about-me .about-skills .skills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: start;
  align-items: center;
  text-align: left;
  row-gap: 2.75rem;
}
#about-me .about-skills .skills h3 {
  margin-bottom: 0.25rem;
}
#about-me .about-skills .skills .tools,
#about-me .about-skills .skills .frontend,
#about-me .about-skills .skills .backend {
  justify-self: center;
}
#about-me .about-skills .skills .tools,
#about-me .about-skills .skills h2 {
  grid-column: 1 / span 2;
}
#about-me .about-skills .skills .tools i,
#about-me .about-skills .skills .frontend i,
#about-me .about-skills .skills .backend i {
  margin-right: 1rem;
}

@media (max-width: 1050px) {
  #about-me {
    height: auto;
    padding-bottom: 10%;
  }
}
@media (max-width: 800px) {
  #about-me .about-skills .skills {
    display: flex;
    flex-direction: column;
  }
  #about-me .about-skills .skills h2 {
    margin-top: 0.75rem;
  }
}
@media (max-width: 500px) {
  #about-me {
    height: 100%;
    padding-bottom: 3rem;
  }
  #about-me .about-skills {
    flex-flow: column nowrap;
  }
}
