.card {
  height: auto;
  position: relative;
  background: #fff;
  padding: 1rem;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}
.card::after {
  content: "";
  position: absolute;
  display: block;
  background: #ffbc00;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
  transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
}
.card:hover {
  cursor: pointer;
}
.card:hover::after {
  transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
}
.card:hover .hover-txt {
  display: block;
  opacity: 1;
  transform: translateY(0);
}
.hover-txt {
  display: none;
  color: #000;
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  transform: translateY(-20%);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition-delay: 200ms;
}

.category {
  display: inline-block;
  color: #fff;
  font-size: 0.55rem;
  text-transform: uppercase;
  padding: 0.4rem 0.6rem;
  border-radius: 15px;
  margin-bottom: 0.5rem;
}
.category-js {
  background-color: #009cff;
}
.category-react {
  background-color: #f99500;
}
.category-spa {
  background-color: #a66bbe;
}
.category-backend {
  background-color: #1ed80f;
}
.category-api {
  background-color: #c70039;
}
.category-mpa {
  background-color: #d80fcc;
}

.card a:link,
.card a:visited {
  color: #000;
}
