/* Footer */
#main-footer {
  background: #000;
  color: #fff;
}
#main-footer a {
  color: #fff;
}
#main-footer .footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
}
#main-footer .footer-container > *:last-child {
  background: #444;
  grid-column: 1 / span 4;
  padding: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
}
#main-footer .footer-container input[type="email"],
#main-footer .footer-container input[type="text"],
#main-footer .footer-container textarea {
  font-family: "Noto Sans JP", sans-serif;
  width: 90%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
#main-footer .footer-container input[type="submit"] {
  width: 90%;
}
#main-footer .footer-container input[type="submit"]:hover {
  color: #ffbc00;
}
#main-footer .footer-container .footer-contact h3 {
  padding-bottom: 1rem;
}
#main-footer .footer-container .social {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
#main-footer .footer-container .social-icons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 50%;
}
#main-footer .footer-container .social-icons a {
  padding: 1rem 0;
}

.personal i {
  padding-bottom: 2rem;
  color: #ffbc00;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 0.5rem 1.5rem;
}
.btn:hover {
  opacity: 0.9;
}
.list li {
  padding: 0.5rem 0;
  border-bottom: #555 dotted 1px;
  width: 90%;
}
#main-footer a:hover {
  color: #ffbc00;
}

@media (max-width: 775px) {
  #main-footer .footer-container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
  #main-footer .footer-container .site-list {
    display: none;
  }
  #main-footer .footer-container > *:last-child {
    grid-column: 1 / span 3;
  }
  #main-footer .footer-container .personal {
    padding: 0 0 0 1rem;
  }
}
@media (max-width: 600px) {
  #main-footer .footer-container {
    grid-template-columns: repeat(1, 1fr);
  }
  #main-footer .footer-container > *:last-child {
    grid-column: 1;
  }
  #main-footer .footer-container > *:first-child,
  #main-footer .footer-container > *:nth-child(2) {
    border-bottom: #444 1px dotted;
    padding-bottom: 1rem;
  }
}
